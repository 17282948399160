import React, { useEffect, useState } from 'react';
import { FaLinkedin, FaGithub, FaEnvelope, FaDiscord } from 'react-icons/fa';
import { SiReact, SiTailwindcss, SiTypescript, SiDotnet, SiDocker, SiPostgresql, SiLinux, SiJirasoftware, SiGit, SiExpress, SiNodedotjs } from 'react-icons/si'; // Import tech icons
import { Trans } from '@lingui/macro'; 
import Navbar from '../components/Navbar'; 
import styled, { useTheme } from 'styled-components';

const MainContent = styled.main`
  flex: 1; /* Allow MainContent to grow */
  padding:20px;
  text-align: left; /* Align text to the left */
  max-width: 1200px; /* Set a maximum width for the main content */
  margin: 0 auto; /* Center the main content horizontally */
`;
const TitleContent = styled.h1`
  color: gray;
  margin-bottom: 10px;
  font-size: 20px;
`;

const TypingAnimation = styled.span`
  white-space: nowrap; /* Prevent text wrapping */
  border-right: 2px solid ${({ theme }) => theme.text}; /* Cursor effect */
  animation: blink 0.7s step-end infinite; /* Blink animation for cursor */

  @keyframes blink {
    50% {
      border-color: transparent; /* Cursor blinks */
    }
  }
`;
const SocialLinks = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 15px; /* Space between the buttons */
`;

const SocialButton = styled.div`
  position: relative; /* Position relative to place the tooltip correctly */
  
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    border: 2px solid ${({ theme }) => theme.text}; /* Border color based on theme */
    border-radius: 50%; /* Make it circular */
    color: ${({ theme }) => theme.text}; /* Icon color based on theme */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effects */

    &:hover {
      transform: scale(1.05); /* Slightly scale up */
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
    }
  }
`;
const SocialButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const TechBadges = styled.div`
  display: flex;
  flex-wrap: wrap; /* Enable wrapping of badges */
  gap: 10px; /* Space between badges */
  margin-left: 20px; /* Add margin to space it from social buttons */
`;

const TechBadge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid ${({ theme }) => theme.text}; /* Border color based on theme */
  border-radius: 50%;
  color: ${({ theme }) => theme.text}; /* Icon color based on theme */
  font-size: 22px; /* Adjust icon size */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover effects */

  &:hover {
    transform: scale(1.05); /* Slightly scale up */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
  }
`;
const Tooltip = styled.span`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.tooltipBackground || 'white'};
  border: 2px solid ${({ theme }) => theme.tooltipBorder || theme.text};
  color: ${({ theme }) => theme.tooltipText || theme.text};
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  max-width: 300px;
  visibility: hidden;

  /* Show tooltip when hovering */
  ${TechBadge}:hover & {
    visibility: visible;
  }

  /* Media query to hide tooltip on mobile */
  @media (max-width: 768px) {
    visibility: hidden;
  }
`;

const TooltipContainer = styled(Tooltip)`
  /* Position the tooltip for social buttons */
  position: absolute;
  bottom: -40px; /* Position it above the button */
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden; /* Start hidden */
`;
const SocialButtonWrapper = styled.div`
  position: relative; /* Position relative to show tooltip */
  
  &:hover ${TooltipContainer} {
    visibility: visible; /* Show tooltip on hover */
  }
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: row; /* Default to row layout */
  margin-top: 40px; /* Space above the sections */
  
  /* Media query for mobile */
  @media (max-width: 768px) {
    flex-direction: column; /* Change to column layout on smaller screens */
  }
`;

const Section = styled.div`
  flex: 1; /* Each section takes 50% of the parent */
  padding: 20px; /* Padding inside each section */
  border: 1px solid ${({ theme }) => theme.text}; /* Optional: border around sections */
  margin: 0 10px; /* Space between sections */
  border-radius: 8px; /* Rounded corners */
`;

const SectionTitle = styled.h2`
  color: gray; /* Title color */
  margin-bottom: 10px;
  font-size: 18px; /* Title font size */
`;

const TimelineContainer = styled.ol`
  position: relative;
  border-left: 2px solid ${({ theme }) => theme.text}; /* Vertical line for the timeline */
  padding-left: 20px; /* Space for the timeline */
`;

const TimelineItem = styled.li`
  position: relative;
  margin-bottom: 20px; /* Space between timeline items */
`;

const TimelineTitle = styled.h3`
  margin-bottom: 5px; /* Space below the title */
  font-size: 16px; /* Title font size */
  font-weight: bold;
  color: ${({ theme }) => theme.text}; /* Title color based on theme */
`;

const TimelineTime = styled.time`
  display: block;
  margin-bottom: 5px; /* Space below the time */
  font-size: 14px; /* Time font size */
  color: gray; /* Time color */
`;

const TimelineDescription = styled.p`
  color: ${({ theme }) => theme.text}; /* Description color based on theme */
`;
const TechnologiesList = styled.p`
  color: gray; /* Set the text color to gray */
  font-size: 16px; /* Adjust the font size as needed */
  margin-top: 5px; /* Space above the technologies list */
`;

const Home = ({ isDarkMode, toggleTheme, switchLanguage, currentLanguage }) => {
    const theme = useTheme();
    const [displayText, setDisplayText] = useState("He");
    const greetings = ["Hello, I'm Artur", "Hej, jestem Artur"];
    const typingSpeed = 100; 
    const backspacingSpeed = 50; 
    const typingDots = ['.', '..', '...'];
    const [dotIndex, setDotIndex] = useState(0);
    const [isTypingComplete, setIsTypingComplete] = useState(false);
    const [currentGreetingIndex, setCurrentGreetingIndex] = useState(0);
    
    useEffect(() => {
      const handleResize = () => {
          if (window.innerWidth > 768) {
              document.body.classList.add('no-scroll');
          } else {
              document.body.classList.remove('no-scroll');
          }
      };
  
      handleResize();
  
      window.addEventListener('resize', handleResize);
  
      return () => {
          window.removeEventListener('resize', handleResize);
          document.body.classList.remove('no-scroll'); 
      };
  }, []);
  

    useEffect(() => {
      let timeout;
  
      if (displayText.length < greetings[currentGreetingIndex].length) {
        timeout = setTimeout(() => {
          setDisplayText((prev) => prev + greetings[currentGreetingIndex][displayText.length]);
        }, typingSpeed);
      } else {
      
        setIsTypingComplete(true);
      }
  
      return () => clearTimeout(timeout); 
    }, [displayText, isTypingComplete, currentGreetingIndex]);
  
    useEffect(() => {
      let backspacingTimeout;
  
      if (isTypingComplete) {

        if (displayText.length > 0) {
          backspacingTimeout = setTimeout(() => {
            setDisplayText((prev) => prev.slice(0, -1));
          }, backspacingSpeed);
        } else {

          setIsTypingComplete(false);
          setCurrentGreetingIndex((prevIndex) => (prevIndex + 1) % greetings.length);
          setDisplayText("He"); 
        }
      }
  
      return () => clearTimeout(backspacingTimeout);
    }, [isTypingComplete, displayText]);
  
    useEffect(() => {

      let dotInterval;
  
      if (isTypingComplete && displayText.length === 0) {
        dotInterval = setInterval(() => {
          setDotIndex((prev) => (prev + 1) % typingDots.length);
        }, 500);
      } else {
        clearInterval(dotInterval);
      }
  
      return () => clearInterval(dotInterval);
    }, [isTypingComplete, displayText]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '97vh' }}>
      <Navbar 
        isDarkMode={isDarkMode} 
        toggleTheme={toggleTheme} 
        switchLanguage={switchLanguage} 
        currentLanguage={currentLanguage} 
      />

      <MainContent style={{ color: theme.text }}>
      <TitleContent>
          {displayText}
          {isTypingComplete && <TypingAnimation>{typingDots[dotIndex]}</TypingAnimation>}
        </TitleContent>
        <p>
            <Trans>
                I am a programmer specializing in JavaScript for both frontend and backend development. 
                Currently, I am seeking new opportunities to leverage my skills and create impactful applications. 
                I have experience working with web application development and enjoy building solutions that make a difference. 
                In my free time, I like to play computer games, board games, and RPGs, practice martial arts, and play guitar. 
              
            </Trans>
            </p>
        <br />
        <p>
          <Trans>
          My favorite tech stack for application development includes React, TailwindCSS, 
          Shadcn, and TypeScript for the frontend, along with Node.js, particularly leveraging 
          the Express.js framework, for the backend. I am a big enthusiast of Linux, which I use 
          on a daily basis. I like to use Docker for my projects, and my favorite text editor for coding is Neovim.
          </Trans>
        </p>
        
        <SocialLinks>
        <SocialButtonsContainer>
            <SocialButtonWrapper>
              <SocialButton>
                <a href="https://www.linkedin.com/in/artur-chlebicki-3151a426a/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin size={20} />
                </a>
                <TooltipContainer>
                  <Trans>LinkedIn</Trans>
                </TooltipContainer>
              </SocialButton>
            </SocialButtonWrapper>

            <SocialButtonWrapper>
              <SocialButton>
                <a href="https://github.com/Artur12555" target="_blank" rel="noopener noreferrer">
                  <FaGithub size={20} />
                </a>
                <TooltipContainer>
                  <Trans>GitHub</Trans>
                </TooltipContainer>
              </SocialButton>
            </SocialButtonWrapper>

            <SocialButtonWrapper>
              <SocialButton>
                <a href="mailto:arturichlebicki@gmail.com">
                  <FaEnvelope size={20} />
                </a>
                <TooltipContainer>
                  <Trans>arturichlebicki@gmail.com</Trans>
                </TooltipContainer>
              </SocialButton>
            </SocialButtonWrapper>

            <SocialButtonWrapper>
              <SocialButton>
                <a  target="_blank" rel="noopener noreferrer">
                  <FaDiscord size={20} />
                </a>
                <TooltipContainer>
                  <Trans>@yxgud</Trans>
                </TooltipContainer>
              </SocialButton>
            </SocialButtonWrapper>
          </SocialButtonsContainer>

          <TechBadges>
            <TechBadge>
              <SiReact />
              <Tooltip>
                <Trans>React</Trans>
              </Tooltip>
            </TechBadge>
            <TechBadge>
              <SiTailwindcss />
              <Tooltip>
                <Trans>TailwindCSS</Trans>
              </Tooltip>
            </TechBadge>
            <TechBadge>
              <SiTypescript />
              <Tooltip>
                <Trans>TypeScript</Trans>
              </Tooltip>
            </TechBadge>
            <TechBadge>
              <SiDocker />
              <Tooltip>
                <Trans>Docker</Trans>
              </Tooltip>
            </TechBadge>
            <TechBadge>
              <SiPostgresql />
              <Tooltip>
                <Trans>PostgreSQL</Trans>
              </Tooltip>
            </TechBadge>
            <TechBadge>
              <SiLinux />
              <Tooltip>
                <Trans>Linux</Trans>
              </Tooltip>
            </TechBadge>
            <TechBadge>
              <SiJirasoftware />
              <Tooltip>
                <Trans>Jira</Trans>
              </Tooltip>
            </TechBadge>
            <TechBadge>
              <SiGit />
              <Tooltip>
                <Trans>Git</Trans>
              </Tooltip>
            </TechBadge>
            <TechBadge>
              <SiExpress />
              <Tooltip>
                <Trans>Express.js</Trans>
              </Tooltip>
            </TechBadge>
            <TechBadge>
              <SiNodedotjs />
              <Tooltip>
                <Trans>Node.js</Trans>
              </Tooltip>
            </TechBadge>
          </TechBadges>
        </SocialLinks>
        
        <SectionsContainer>
          <Section>
            <SectionTitle>
              <Trans>Education</Trans>
            </SectionTitle>
            <TimelineContainer>
              <TimelineItem>
                <TimelineTitle>
                  <Trans>Gdańsk University of Technology</Trans>
                </TimelineTitle>
                <TimelineTime>10/2024 - 03/2026</TimelineTime>
                <TimelineDescription>
                  <Trans>Part-time Master's degree in Computer Science.</Trans>
                </TimelineDescription>
              </TimelineItem>
              <TimelineItem>
                <TimelineTitle>
                  <Trans>Polish-Japanese Academy of Information Technology</Trans>
                </TimelineTitle>
                <TimelineTime>10/2020 - 03/2024</TimelineTime>
                <TimelineDescription>
                  <Trans>Bachelor's degree in Computer Science, specializing in web applications.</Trans>
                </TimelineDescription>
              </TimelineItem>
            </TimelineContainer>
          </Section>
          <Section>
            <SectionTitle>
              <Trans>Experience</Trans>
            </SectionTitle>
            <TimelineContainer>
              <TimelineItem>
                <TimelineTitle>
                  <Trans>Fullstack Developer at Esotiq & Henderson S.A.</Trans>
                </TimelineTitle>
                <TimelineTime>07/2022 - 06/2023</TimelineTime>
                <TimelineDescription>
                  <Trans>Successfully orchestrated the deployment and
                        scalability of our projects using Docker, skillfully
                        creating Docker Compose files and images to improve
                        the infrastructure.<br></br>
                        Enhanced and maintained web crawler functionalities,
                        optimizing performance and data retrieval processes.
                        Developed robust CRUD operations to improve system
                        capabilities.<br></br>
                        Expanded and managed the project database, ensuring
                        both scalability and optimal performance.
                        Fostered effective problem-solving by actively
                        communicating with colleagues to collaboratively devise
                        solutions for challenges.
                </Trans>
                </TimelineDescription>
                <TechnologiesList>
                    <Trans>JavaScript, React, Node.js, Docker, PostgreSQL</Trans>
                </TechnologiesList>
              </TimelineItem>
            </TimelineContainer>
          </Section>
        </SectionsContainer>
      </MainContent>
      
    </div>
  );
};

export default Home;
