import React, { useState } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { FaCheck } from 'react-icons/fa';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Link } from 'react-router-dom';

const Navbar = ({ isDarkMode, toggleTheme, switchLanguage, currentLanguage }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { i18n } = useLingui();

  const handleLanguageChange = (lang) => {
    switchLanguage(lang);
    i18n.activate(lang);
    setDropdownOpen(false);
  };

  return (
    <nav className={`border-gray-200 ${isDarkMode ? 'text-gray-300' : 'text-black'} bg-transparent`}>
      <div className="max-w-screen-xl flex flex-col md:flex-row items-center justify-between py-16 mx-auto p-4">
        <div className="flex flex-col mb-4 md:mb-0">
          <span className={`self-center text-2xl font-semibold whitespace-nowrap`}>
            <Trans>Artur Chlebicki</Trans>
          </span>
          <span className={`self-center text-md font-medium whitespace-nowrap`}>
            <Trans>BEng in Computer Science</Trans>
          </span>
        </div>

        <div className="flex items-center md:order-2 space-x-1 md:space-x-3 rtl:space-x-reverse">
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-7 mx-5">
            <Link to="/" className={`text-md hover:underline mb-2 md:mb-0`}>
              <Trans>Homepage</Trans>
            </Link>
            <Link to="/wakatime" className={`text-md hover:underline mb-2 md:mb-0`}>
              <Trans>Dashboard</Trans>
            </Link>
            <a href="/cv" className={`text-md hover:underline mb-2 md:mb-0`}>
              <Trans>CV</Trans>
            </a>
          </div>

          <div className="flex items-center justify-center p-1 rounded-lg cursor-pointer mx-4 hover:border-2"
            style={{
              borderColor: isDarkMode ? 'white' : '#D1D5DB',
              minWidth: '40px',
              height: '40px'
            }}>
            <DarkModeSwitch
              checked={isDarkMode}
              onChange={toggleTheme}
              size={22}
            />
          </div>

          <div className="relative">
            <button
              type="button"
              onClick={() => setDropdownOpen((prev) => !prev)}
              className={`inline-flex items-center justify-center font-medium p-1 rounded-lg cursor-pointer hover:border-2`}
              style={{ 
                borderColor: isDarkMode ? 'white' : '#D1D5DB',
                minWidth: '40px',
                height: '40px'
              }}   
            >
              <img
                src={currentLanguage === "en" ? "/eng.svg" : "/pl.svg"}
                alt="Current Language"
                className="w-5 h-5" 
              />
            </button>
            {dropdownOpen && (
              <div
                className={`absolute left-1/2 transform -translate-x-1/2 z-50 mt-2 text-base list-none rounded-lg shadow ${isDarkMode ? 'bg-[#111] border border-white' : 'bg-white'}`} 
                style={{ minWidth: '120px' }}
              >
          <ul className="font-medium">
  <li className={`flex items-center justify-between h-10 ${isDarkMode ? 'hover:bg-opacity-10 hover:bg-white' : 'hover:bg-gray-100'}`}>
    <a
      onClick={() => handleLanguageChange("en")}
      className={`flex items-center block px-4 py-2 text-sm ${isDarkMode ? 'text-gray-300' : 'text-black'}`}
    >
      <img src="/eng.svg" alt="English Flag" className="mr-2 w-5 h-5" />
      <span className="w-24 text-left"> 
        <Trans>English</Trans>
      </span>
    </a>
    {currentLanguage === "en" && (
      <FaCheck className={`mr-2 ${isDarkMode ? 'text-gray-300' : 'text-black'}`} />
    )}
  </li>
  <li className={`flex items-center justify-between h-10 ${isDarkMode ? 'hover:bg-opacity-10 hover:bg-white' : 'hover:bg-gray-100'}`}>
    <a
      onClick={() => handleLanguageChange("pl")}
      className={`flex items-center block px-4 py-2 text-sm ${isDarkMode ? 'text-gray-300' : 'text-black'}`}
    >
      <img src="/pl.svg" alt="Polish Flag" className="mr-2 w-5 h-5" />
      <span className="w-22 text-left">
        <Trans>Polski</Trans>
      </span>
    </a>
    {currentLanguage === "pl" && (
      <FaCheck className={`mr-2 ${isDarkMode ? 'text-gray-300' : 'text-black'}`} />
    )}
  </li>
</ul>


              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
