import React from 'react';
import styled, { useTheme } from 'styled-components';
import Navbar from '../components/Navbar';
import { Trans, t } from '@lingui/macro';


const MainContent = styled.main`
  flex: 1; /* Allow MainContent to grow */

  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between; /* Distribute content between title and last updated */
  align-items: center; /* Align vertically */
  margin-bottom: 20px;
`;

const Title = styled.h1`
  color: gray;
  margin: 0; /* Remove default margins to align with last updated text */
`;

const LastUpdated = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.text};
  font-size: 14px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StatBox = styled.div`
  flex: 1;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.text};
  margin: 0 10px;
  border-radius: 8px;
  text-align: center;
`;

const LanguagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const LanguageRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.text};
`;

const Language = styled.span`
  color: ${({ theme }) => theme.text};
`;

const Time = styled.span`
  color: gray;
`;

const WakaTime = ({ isDarkMode, toggleTheme, switchLanguage, currentLanguage }) => {
    const theme = useTheme();
  
    const programmingLanguages = [
      { name: 'JavaScript', hours: 620, minutes: 30 },
      { name: 'TypeScript', hours: 351, minutes: 10 },
      { name: 'Python', hours: 95, minutes: 15 },
      { name: 'SQL', hours: 41, minutes: 25 },
      { name: 'HTML', hours: 22, minutes: 5 },
      { name: 'Java', hours: 32, minutes: 0 },
      { name: 'C#', hours: 5, minutes: 45 },
      { name: 'C++', hours: 26, minutes: 20 },
    ];
  
    const totalTime = programmingLanguages.reduce(
      (acc, lang) => {
        acc.hours += lang.hours;
        acc.minutes += lang.minutes;
        return acc;
      },
      { hours: 0, minutes: 0 }
    );
  
    totalTime.hours += Math.floor(totalTime.minutes / 60);
    totalTime.minutes = totalTime.minutes % 60;
  
    const mostUsedLanguage = programmingLanguages.reduce((prev, current) => {
      const prevTotal = prev.hours * 60 + prev.minutes;
      const currentTotal = current.hours * 60 + current.minutes;
      return (prevTotal > currentTotal) ? prev : current;
    }, programmingLanguages[0]);
  
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '97vh' }}>
        <Navbar 
          isDarkMode={isDarkMode} 
          toggleTheme={toggleTheme} 
          switchLanguage={switchLanguage} 
          currentLanguage={currentLanguage} 
        />
  
        <MainContent style={{ color: theme.text }}>
          <Header>
            <Title><Trans>My Wakatime Stats</Trans></Title>
            <LastUpdated>
              <Trans>Last updated: 1 October</Trans><br />
              <small style={{ color: 'gray' }}><Trans>(updates every 30 days)</Trans></small>
            </LastUpdated>
          </Header>
  
          <StatsContainer>
            <StatBox>
              <h3><Trans>Total Programming Time</Trans></h3>
              <p>
                <Trans>
                  {totalTime.hours} hours {totalTime.minutes} minutes
                </Trans>
              </p>
              <small style={{ color: 'gray' }}><Trans>since July 2023</Trans></small>
            </StatBox>
            <StatBox>
              <h3><Trans>Most Used Language</Trans></h3>
              <p>{mostUsedLanguage.name}</p>
              <p>
                <Trans>
                  {mostUsedLanguage.hours} hours {mostUsedLanguage.minutes} minutes
                </Trans>
              </p>
            </StatBox>
          </StatsContainer>
  
          <LanguagesContainer>
            {programmingLanguages
              .sort((a, b) => (b.hours * 60 + b.minutes) - (a.hours * 60 + a.minutes))
              .map((lang) => (
                <LanguageRow key={lang.name}>
                  <Language>{lang.name}</Language>
                  <Time>
                    <Trans>
                      {lang.hours} hours {lang.minutes} minutes
                    </Trans>
                  </Time>
                </LanguageRow>
              ))}
          </LanguagesContainer>
        </MainContent>
  
      </div>
    );
  };
  
  export default WakaTime;