import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { messages as enMessages } from "./locales/en/messages";
import { messages as plMessages } from "./locales/pl/messages";
import App from "./App";
import { BrowserRouter as Router } from 'react-router-dom';
import { TbRuler3 } from "react-icons/tb";

i18n.load({
  en: enMessages,
  pl: plMessages,
});
i18n.activate("en");

function Main() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState("en");

  useEffect(() => {
    const savedTheme = localStorage.getItem("isDarkMode");
    const savedLanguage = localStorage.getItem("language");

    if (savedTheme !== null) {
      setIsDarkMode(savedTheme === "true");
    }
    if (savedLanguage) {
      setCurrentLanguage(savedLanguage);
      i18n.activate(savedLanguage); 
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem("isDarkMode", newTheme);
  };

  const switchLanguage = (lang) => {
    console.log(`Switching language to: ${lang}`);
    setCurrentLanguage(lang);
    localStorage.setItem("language", lang); 
    i18n.activate(lang);
  };

  useEffect(() => {
    console.log(`Current language is: ${currentLanguage}`);
  }, [currentLanguage]);

  return (
    <I18nProvider i18n={i18n}>
      <Router>
        <App
          isDarkMode={isDarkMode}
          toggleTheme={toggleTheme}
          switchLanguage={switchLanguage}
          currentLanguage={currentLanguage}
        />
      </Router>
    </I18nProvider>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));
