import React from 'react';
import styled from 'styled-components';

const CVContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #000;
  max-width: 210mm; /* A4 width */
  max-height: 297mm; /* A4 height */
  padding: 10px;
  box-sizing: border-box;

  @media print {
    width: 100%;
    height: auto; /* Auto height for print */
    overflow: hidden; /* Prevents any overflow */
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
`;

const Section = styled.section`
  flex: ${(props) => (props.flexBasis ? props.flexBasis : '1')};
  width: 100%;
  border: 1px solid #ccc;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #f9f9f9;

  h2 {
    margin-bottom: 1px;
    font-size: 16px;
    text-transform: uppercase;
  }

  p, ul, li {
    font-size: 12px;
    line-height: 1.4;
  }

  ul {
    padding-left: 20px;
  }
`;

const NameTitleContainer = styled.div`
  text-align: center;
  margin-bottom: 2px;
`;

const ContactInfo = styled.div`
  text-align: center;
  margin-top: 5px; /* Adjust margin as necessary */
  font-size: 14px;

  & span {
    margin-right: 15px; /* Space between email and phone number */
  }
`;

const Name = styled.h1`
  font-size: 32px;
  margin-bottom: 5px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduced gap for smaller layout */
`;

const SkillSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Reduced gap between each skill */
`;

const SkillItem = styled.div`
  padding: 6px; /* Smaller padding */
  border: 2px solid black; /* Black border around each skill */
  border-radius: 5px;
  font-size: 11px; /* Smaller font size */
  white-space: nowrap; /* Prevents text wrapping */
  box-sizing: border-box;
`;

const HardSkillItem = styled.div`
  padding: 6px; /* Smaller padding */
  font-size: 11px; /* Smaller font size */
  white-space: nowrap; /* Prevents text wrapping */
  color: gray;
  margin-left: 16px;
`;

const PrintButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f1f1f1;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  @media print {
    display: none; /* Hide the button when printing */
  }
`;

const ConsentStatement = styled.div`
  display: none; /* Initially hidden */

  @media print {
    display: block; /* Show during printing */
    margin-top: 20px;
    font-size: 10px; /* Adjust font size as needed */
    text-align: center; /* Center align the consent statement */
  }
`;

const AchievementsSection = styled(Section)`
  flex-basis: 30%; /* Taking up 30% of the width */
`;

const ProjectsSection = styled(Section)`
  flex-basis: 70%; /* Taking up 70% of the width */
`;

const HardSkillsUsedSection = styled(Section)`
  margin-top: 10px; /* Adds space above this section */
`;

const CV = () => {
  return (
    <CVContainer>
      <NameTitleContainer>
        <Name>Artur Chlebicki</Name>
        <Title>Software Engineer & Full-Stack Developer</Title>
      </NameTitleContainer>

      <ContactInfo>
        <span>arturichlebicki@gmail.com</span>| <span>+48 518 566 662</span>| <span>www.chlebicki.art</span>
      </ContactInfo>

      <FlexRow>
        <Section>
          <h2>PROFILE</h2>
          <p>
            I studied at the Polish-Japanese Academy of Information Technology (PJAIT) for 3.5 years, earning a BEng in Computer Science. I have one year of commercial experience working for a large company on a significant project. Currently, I am seeking new job opportunities and pursuing part-time studies at Gdańsk University of Technology.
          </p>
        </Section>
      </FlexRow>

      <FlexRow>
        <Section>
          <h2>TECH STACK / SKILLS</h2>
          <SkillsContainer>
            <div>
              <h3>Soft Skills</h3>
              <SkillSection>
                <SkillItem>Teamwork</SkillItem>
                <SkillItem>Communication</SkillItem>
                <SkillItem>Problem Solving</SkillItem>
                <SkillItem>Time Management</SkillItem>
                <SkillItem>Creativity</SkillItem>
                <SkillItem>Adaptability</SkillItem>
                <SkillItem>Leadership</SkillItem>
                <SkillItem>Critical Thinking</SkillItem>
                <SkillItem>Availability</SkillItem>
              </SkillSection>
            </div>

            <div>
              <h3>Hard Skills</h3>
              <SkillSection>
                <SkillItem>React</SkillItem>
                <SkillItem>TailwindCSS</SkillItem>
                <SkillItem>TypeScript</SkillItem>
                <SkillItem>Docker</SkillItem>
                <SkillItem>PostgreSQL</SkillItem>
                <SkillItem>Linux</SkillItem>
                <SkillItem>Jira</SkillItem>
                <SkillItem>Git</SkillItem>
                <SkillItem>Express.js</SkillItem>
                <SkillItem>Node.js</SkillItem>
              </SkillSection>
            </div>
          </SkillsContainer>
        </Section>
      </FlexRow>

      <FlexRow>
        <Section style={{ flexBasis: '43%' }}>
          <h2>PROFESSIONAL EXPERIENCE</h2>
          <ul>
            <li>
              <strong>Fullstack Developer</strong> - Esotiq & Henderson S.A.
              <br />
              07/2022 - 06/2023 | Gdańsk, Poland
              <br />
              TypeScript, React, Express.js, Docker, PostgreSQL
            </li>
          </ul>
        </Section>

        <Section style={{ flexBasis: '40%' }}>
          <h2>EDUCATION</h2>
          <ul>
            <li>
              <strong>Bachelor of Engineering in Computer Science</strong> - Polish-Japanese Academy of Information Technology
              <br />
              10/2020 - 03/2024 | Gdańsk, Poland
            </li>
            <li>
              <strong>Master's in Computer Science</strong> - Gdańsk University of Technology
              <br />
              10/2024 - 03/2026 | Gdańsk, Poland
            </li>
          </ul>
        </Section>

        <AchievementsSection>
          <h2>ACHIEVEMENTS</h2>
          <ul>
            <li>
              <strong>1st place at Odyssey of the Mind</strong> - 04/2021
              <br />
              We designed and constructed a
              single-person electric car from the
              ground up, using Arduino for the
              software. Our project earned first
              place in Poland and secured fourth
              place in the global competition.
            </li>
          </ul>
        </AchievementsSection>
      </FlexRow>

      <FlexRow>
        <ProjectsSection>
          <h2>MOST NOTABLE PROJECT</h2>
          <ul>
            <li>
              <strong>Graduation project - Bussintime</strong> - 09/2023 - 03/2024
              <br />
              Professional social gathering app with web sockets, dockerized backend, over 150 pages detailed documentation. Released as a website and mobile app.
            </li>
          </ul>

            <SkillSection>
              <HardSkillItem>Node.js</HardSkillItem>
              <HardSkillItem>Java</HardSkillItem>
              <HardSkillItem>TypeScript</HardSkillItem>
              <HardSkillItem>React</HardSkillItem>
              <HardSkillItem>WebSockets</HardSkillItem>
              <HardSkillItem>Docker</HardSkillItem>
              <HardSkillItem>PostgreSQL</HardSkillItem>
            </SkillSection>
        </ProjectsSection>
      </FlexRow>

      <PrintButton onClick={() => window.print()}>
        Print CV
      </PrintButton>

      <ConsentStatement>
        I consent to the processing of my personal data for the purposes necessary for the recruitment process (in accordance with the Act of May 10, 2018 on the protection of personal data (Journal of Laws 2018, item 1000) and in accordance with the Regulation of the European Parliament and of the Council (EU) 2016/679 of April 27, 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (GDPR/RODO)).
      </ConsentStatement>
    </CVContainer>
  );
};

export default CV;
