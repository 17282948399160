import React from 'react';
import styled, { useTheme } from 'styled-components';

const FooterContainer = styled.footer`
  width: 100%;
  color: ${({ theme }) => theme.text}; /* Use theme text color */
  text-align: center; /* Center the text */
  font-size: 16px; /* Font size */
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();

  return (
    <FooterContainer>
      &copy; {currentYear} Artur Chlebicki
    </FooterContainer>
  );
};

export default Footer;
